<template>
	<v-layout column>
		<v-flex my-3 shrink title v-text="title" />
		<w-data-table class="elevation-1 my-1" :headers="headers" hide-actions :items="items" :loading="loading">
			<template v-slot:no-data>
				<slot name="no-data" />
			</template>
			<template v-slot:items="{ item }">
				<td>
					<v-flex justify-center text-xs-center>
						<v-img v-if="item.logo" contain height="50px" loading="lazy" :src="item.logo" />
					</v-flex>
				</td>
				<td>
					<v-flex :class="{ 'crossed--text red--text': item.deleted_at }" justify-center text-xs-center v-text="item.title" />
				</td>
				<td>
					<v-flex :class="{ 'crossed--text red--text': item.deleted_at }" justify-center text-xs-start>
						{{ item.description ? item.description : '' }}
					</v-flex>
				</td>
				<td>
					<v-flex justify-center text-xs-center>
						<w-btn color="info" :disabled="item.deleted_at" flat small @click="openDescription(item)">
							{{ $t('application.more') }}
						</w-btn>
					</v-flex>
				</td>
				<slot name="last-row" :item="item">
					<td>
						<v-flex justify-center text-xs-center>
							<w-btn color="" flat :icon="actionIcon" :loading="applicationLoading == item.id" mini @click="callAction(item)" />
						</v-flex>
					</td>
				</slot>
			</template>
		</w-data-table>
		<w-dialog
			:title="$t('application.title', { appname: application ? application.title : '' })"
			:value="dialog"
			:width="$vuetify.breakpoint.mdAndUp ? '50vw' : '600px'"
			@close="closeDescription()"
		>
			<ApplicationDetails :value="application" />
		</w-dialog>
	</v-layout>
</template>

<script>
export default {
	name: 'ApplicationsTable',
	components: {
		ApplicationDetails: () => ({
			component: import('@/components/Applications/ApplicationDetails')
		})
	},
	props: {
		action: {
			required: true,
			type: Function
		},
		actionIcon: {
			required: true,
			type: String
		},
		headers: {
			required: false,
			type: Array,
			default: () => []
		},
		items: {
			required: false,
			type: Array,
			default: () => []
		},
		loading: {
			default: false,
			required: false,
			type: Boolean
		},
		title: {
			required: true,
			type: String
		}
	},
	data: function () {
		return {
			application: null,
			applicationLoading: null,
			dialog: false
		}
	},
	methods: {
		callAction: function (application) {
			const action = this.action(application)
			if (typeof action == 'object' && typeof action.then == 'function') {
				this.applicationLoading = application.id
				action.finally(() => {
					this.applicationLoading = null
				})
			}
		},
		closeDescription: function () {
			this.dialog = false
			this.application = null
		},
		openDescription: function (application) {
			this.application = application
			this.dialog = true
		}
	}
}
</script>
